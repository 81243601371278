import { clone, getObjectKeys } from '@avvoka/shared'
import { useTemplateVersionStore } from '@stores/generic/templateVersion.store'
import { getActivePinia } from 'pinia'
import { watch, toRaw, toRef } from 'vue'
import type { InjectionKey, Ref } from 'vue'
import type { Store } from 'vuex'
import { findChangedParty, saveEntry, listenOnStatusChanges, setLoopCounts } from './QuestionnaireHelpers'
import { compareValues } from './compare_values/compare_values'
import Utils from './utils'
import { getVisibilityConditionForQuestion } from './VisibilityConditions'
import { PROCESS_STATES, getStateFromBoolean } from './questionnaire/sidekiq_states'
import { toPlainText } from './dom_utils'
import { type Datasheet, dsCellName } from './questionnaire/types/datasheets/utils'
import { synchronizeRefs } from './storeHelpers'
import { DATASHEET_DIVIDER_TOKEN } from './questionnaire/types/datasheets/reusable'
import { type DatasheetRowValues } from './datasheets/rows/types'
import { DOMEmit } from './dom_utils'
import { fetchLiveDemoData } from '../views/templates/live-demo-utils'
import type { IOperation } from './operations/OperationsUtilts'
import useDebounce from './_abstract/utils/debounce'
import { applyEntries, currencySplitter } from './template_packs/save_entry_utils'

const axios = Utils.axios

export interface QStoreContext {
  state: IQuestionnaireStore
  commit: SmartStore<IQuestionnaireStore>['commit']
  dispatch: SmartStore<IQuestionnaireStore>['dispatch']
  getters: SmartStore<IQuestionnaireStore>['getters']
}

export type DefaultMode = 'templating' | 'templating+demo' | 'demo'

export interface IQuestionnaireStore {
  document_id: number
  clip_id: number
  templatePackID: number
  documentTitle: string
  parties: string[]
  questionnaires: string[]
  questions: Backend.Questionnaire.IQuestion[]
  newTemplatePackQuestions: Backend.Questionnaire.IQuestion[]
  operations?: IOperation[]
  mass_selected_questions: NonNullable<Backend.Questionnaire.QuestionID>[]
  generated_questions: NonNullable<Backend.Questionnaire.QuestionID>[]
  selected_question: Backend.Questionnaire.QuestionID | null
  expanded_info_text_modals: string[]
  opened_info_text_modals: string[]
  selected_party?: string
  compress_questions: boolean
  show_template_settings: boolean
  dragging: boolean
  default_mode?: string
  answers: Record<string, string | string[]>
  display_toolbar: boolean
  show_dialog: boolean
  resolvedConditions: null | object
  documentConditions: null | Record<string, boolean>
  conditionLogs: null | object
  current_section: number
  errors: Backend.Questionnaire.Errors
  active_requests: Record<string, number | number[]>
  scheduled_requests: Record<string, number | number[]>
  questionnaire_type: 'obq' | 'ld' | 'dq'
  saved_answers: Record<string, string | string[]>[]
  active_filter?: {
    filter: (arg0: Backend.Questionnaire.IQuestion) => boolean
    text: string
  }
  showConditionLogic?: boolean
  changed_attrs: { att: string; loopCount: number }[]
  loop_counts: Record<string, number>
  sidekiq_status: PROCESS_STATES
  process_sidekiq_status: boolean
  datasheets: Datasheet[]
  datasheet_values: Record<string, string[] | undefined>
  formattedNumbers: Record<string, string[]>
  allow_suggested_definition: boolean
  allow_change_q_type: boolean
  allowSavedEntries: boolean
  allowCreateDocument: boolean
  allowFullscreen: boolean
  created_from_pack: boolean
  fastTpq: boolean
  templates_by_attributes?: Record<string, string[]>
  show_editor?: boolean
  allowUseSuggestedVC: boolean
  bShowExportAnswers: boolean
  newQuestionAsPack: boolean
  last_saved_entries: Record<string, Record<number, string[]|string>>
  uuidBlacklist: string[]
  searchQuery: string
}

const actions = {
  toggleCompressQuestions({ commit }: SmartStore<IQuestionnaireStore>) {
    commit('TOGGLE_COMPRESS_QUESTIONS')
  },
  toggleSettings({ commit }: SmartStore<IQuestionnaireStore>) {
    commit('TOGGLE_SHOW_SETTINGS')
  },
  selectQuestionnaire({ commit }: SmartStore<IQuestionnaireStore>, partyName: string) {
    commit('SET_MASS_SELECT', [])
    commit('SET_SELECTED_PARTY', partyName)
  },
  async on_answers_change({ commit, state, getters, dispatch }: QStoreContext, force?: boolean) {
    

    if (state.questionnaire_type === 'ld') {
      if (!force && !state.changed_attrs.length) return
      fetchLiveDemoData({ state, getters, commit, dispatch })
    } else if (state.questionnaire_type === 'dq') {
      const changed_attrs = [...state.changed_attrs]
      for (const { att, loopCount } of changed_attrs) {
        const index = state.changed_attrs.findIndex((item) => item.att == att && item.loopCount == loopCount)
        state.changed_attrs.splice(index, 1)
        await dispatch('save_entry', { att, loopCount, start: true })
      }
    }
  },
  save_entry({ commit, state, dispatch, getters }: QStoreContext, { att, loopCount, start }: { att: string; loopCount: number; start: boolean }) {
    if (start) {
      commit('START_SAVE_ENTRY', { att, loopCount })
      if (state.process_sidekiq_status)
        commit('SET_SIDEKIQ_STATUS', {
          sidekiq_status: PROCESS_STATES.PROCESSING,
          immediate: true
        })
      if (
        Object.keys(state.active_requests).length === 1 &&
        Object.values(state.active_requests).every((el) => {
          if (Array.isArray(el)) return el.length < 2
          else return el < 2
        })
      ) {
        saveEntry({ state, dispatch, commit }, att, state.answers[att], loopCount - 1)
      }
    } else {
      commit('END_SAVE_ENTRY', { att, loopCount })
      const dependentAtts = getters.nonDeletedQuestions
        .filter((q) => {
          const dependencies = q.opts.datasheet_dependencies
          if (!dependencies) return false
          const dependsOnAttribute = dependencies.some((dep) => {
            return dep.attribute == att
          })
          return dependsOnAttribute
        })
        .map((q) => q.att)
      const promises = dependentAtts.map((dependentAtt) =>
        dispatch('fetch_datasheet_values', {
          att: dependentAtt,
          loopCount: loopCount - 1
        })
      )
      Promise.all(promises).then(() => {
        const nextAtt = Object.keys(state.active_requests)[0]
        if (nextAtt) {
          const nextAttLoopCount = state.active_requests[nextAtt][0] - 1
          saveEntry({ state, dispatch, commit }, nextAtt, state.answers[nextAtt], nextAttLoopCount)
        }
      })
    }
  },
  fetch_saved_answers({ commit }: QStoreContext) {
    const documentId = AvvStore.state.document_id

    let url = undefined
    if (typeof documentId === 'number') {
      url = `/documents/${documentId}/test_entries`
    } else {
      const template_id = AvvStore.state.template_id
      if (template_id === -1) return
      url = `/templates/${template_id}/test_entries`
    }

    axios.get(url).then((response) => {
      commit('SET_SAVED_ANSWERS', response.data)
    })
  },
  fetch_entry_detail({ commit, getters }: QStoreContext, id: number) {
    const documentId = AvvStore.state.document_id

    let url = undefined
    if (typeof documentId === 'number') {
      url = `/documents/${documentId}/test_entries/${id}`
    } else {
      const template_id = AvvStore.state.template_id
      if (template_id === -1) return
      url = `/templates/${template_id}/test_entries/${id}`
    }

    axios.get(url).then((response) => {
      for (const [att, value] of Object.entries(response.data.answers)) {
        commit('ADD_ANSWER', { att, value })
        const repeater_id = getters.nonDeletedQuestions.find((q) => q.att === att)?.opts['repeater-id']
        if (Array.isArray(value) && repeater_id) {
          const loopCount = value.length - 1
          commit('SET_LOOP_COUNT', {
            repeater_id: repeater_id,
            value: loopCount
          })
        }
      }
    })
  },
  save_answers({ state, dispatch }: QStoreContext, name: string) {
    const template_id = AvvStore.state.template_id
    const url = `/templates/${template_id}/test_entries`
    const params = {
      template_test_entry: {
        allow_usage_in_documents: false,
        answers: state.answers,
        title: name
      }
    }
    axios.post(url, params).then(() => dispatch('fetch_saved_answers'))
  },
  delete_saved_entry({ dispatch }: QStoreContext, id: number) {
    const template_id = AvvStore.state.template_id
    const url = `/templates/${template_id}/test_entries/${id}`
    const config = {
      url,
      method: 'delete'
    }
    axios(config)
      .then((response) => {
        if (response.status === 200) alert(localizeText('questionnaire.notices.deleted_answers'))
        else alert(localizeText('questionnaire.error'))
        return response
      })
      .then(() => dispatch('fetch_saved_answers'))
      .catch(() => {
        console.error(localizeText('questionnaire.notices.deleted_answers_error'))
      })
  },
  update_saved_entry({ dispatch, state }: QStoreContext, { id, allow_usage_in_documents }: { id: number; allow_usage_in_documents: boolean }) {
    const template_id = AvvStore.state.template_id
    const url = `/templates/${template_id}/test_entries/${id}`
    const params = {
      template_test_entry: {
        allow_usage_in_documents,
        answers: state.answers
      }
    }
    return axios.patch(url, params).then((response) => {
      if (response.status === 200) {
        avv_dialog({
          snackMessage: localizeText('questionnaire.updated_answers'),
          snackStyle: 'success'
        })
      }
    })
  },
  update_allow_usage_in_doc({ dispatch, state }: QStoreContext, { id, allow_usage_in_documents }: { id: number; allow_usage_in_documents: boolean }) {
    const template_id = AvvStore.state.template_id
    const url = `/templates/${template_id}/test_entries/${id}`
    const params = {
      template_test_entry: {
        allow_usage_in_documents
      }
    }
    const popupText = allow_usage_in_documents ? localizeText('questionnaire.update_allow_usage_in_doc.true') : localizeText('questionnaire.update_allow_usage_in_doc.false')
    return axios.patch(url, params).then((response) => {
      if (response.status === 200) {
        avv_dialog({
          snackMessage: popupText,
          snackStyle: 'success'
        })
      }
      return response
    })
  },
  async fetch_sidekiq_status({ commit }: QStoreContext) {
    const url = `questionnaire_state`
    const response = await axios.get<{ questionnaire_locked: boolean }>(url)
    if (response.status === 200) {
      const sidekiq_status = getStateFromBoolean(response.data.questionnaire_locked)
      commit('SET_SIDEKIQ_STATUS', { sidekiq_status, immediate: true })
    }
  },
  async fetch_datasheet_values({ commit, state, getters }: QStoreContext, { att, loopCount }: { att: string; loopCount?: number }) {
    return new Promise<void>(async (resolve) => {
      const urlParams = new URLSearchParams()
      urlParams.set('attribute', att)
      const question = getters.nonDeletedQuestions.find((q) => q.att === att)
      if (!question || question.type !== 'datasheets') {
        resolve()
        return
      }
      const dependencies = question.opts.datasheet_dependencies ?? []
      const properLoopCount = Utils.notANumber(loopCount) ? 0 : loopCount!
      dependencies.forEach(({ attribute }) => {
        const value = state.answers[attribute]
        urlParams.set(attribute, dsCellName(Array.isArray(value) ? value[properLoopCount] : value))
      })
      let id: number
      let controller: string
      if (state.created_from_pack) {
        id = state.clip_id
        controller = 'clips'
      } else if (state.questionnaire_type === 'dq') {
        id = AvvStore.state.document_id
        controller = 'documents'
      } else {
        id = AvvStore.state.template_id
        controller = 'templates'
      }
      const url = `/${controller}/${id}/datasheet_values?${urlParams.toString()}`
      const response = await axios.get(url)
      commit('SET_DATASHEET_VALUES', {
        values: response.data.datasheet_values.map(([value, id]: Array<string>) => `${value}${DATASHEET_DIVIDER_TOKEN}${id}`),
        att,
        loopCount: properLoopCount
      })
      resolve()
    })
  },
  async reload_datasheet_answers(
    { dispatch, state, getters }: QStoreContext,
    {
      datasheetId,
      oldValues,
      newValues,
      loopCount
    }: {
      datasheetId: string
      oldValues?: DatasheetRowValues
      newValues?: DatasheetRowValues
      loopCount?: number
    }
  ) {
    // Find all questions that depend on datasheet id (must be non-strict equals due to different both numbers / strings appearing as arguments)
    const affectedQuestions = getters.nonDeletedQuestions.filter(({ opts }) => opts && opts.datasheet_id == datasheetId)

    return Promise.all(
      affectedQuestions.map(async ({ att, opts }) => {
        // Find display header
        const answer = state.answers[att]
        const header = opts.datasheet_display_header_id
        // Fetch datasheet values for all questions and update answers if possible
        if (Array.isArray(answer)) {
          const answerLength = Utils.notANumber(loopCount) ? answer.length : Math.max(answer.length, loopCount!)
          for (let i = 0; i < answerLength; i++) {
            const update = oldValues && newValues && answer[i] === oldValues[header] && newValues[header] !== oldValues[header]

            if (update) {
              state.answers[att][i] = newValues[header]
            }

            await dispatch('fetch_datasheet_values', { att, loopCount: i })
          }
        } else {
          const update = oldValues && newValues && answer === oldValues[header] && newValues[header] !== oldValues[header]
          if (update) {
            state.answers[att] = newValues[header]
          }

          await dispatch('fetch_datasheet_values', { att })
        }
      })
    )
  },
  on_document_conditions_change(store: QStoreContext, value: Backend.Questionnaire.ResolvedConditionsByDoc) {
    Object.entries(value).forEach(([id, value]) => {
      const editorElement = document.querySelector(`#q-editor-${id}`)
      if(!editorElement) return
      editorElement.setAttribute('data-active', value.toString())
    })
    DOMEmit('document-conditions-update')
  },
  on_resolved_conditions_change(store: QStoreContext, value) {
    const unresolvedQuestions = store.getters.nonDeletedQuestions.filter((q) => {
      if (!q.att) return false
      if (!store.state.answers[q.att]) return false
      if (!q.cond) return false
      else return !value[q.cond]
    })
    unresolvedQuestions.forEach((q) => {
      store.commit('REMOVE_ANSWER', { att: q.att, wipe: true })
    })
  }
}

// Map functions to return types
type ReadonlyIGetters = {
  [K in keyof IGetters]: ReturnType<IGetters[K]>
}
interface IGetters {
  questionsByAtt(state: IQuestionnaireStore, getters: ReadonlyIGetters): Record<string, Backend.Questionnaire.IQuestion>
  questionByAtt: (state: IQuestionnaireStore, getters: ReadonlyIGetters) => (att: string) => Backend.Questionnaire.IQuestion | undefined
  nonDeletedQuestions: (state: IQuestionnaireStore, getters: ReadonlyIGetters) => Backend.Questionnaire.IQuestion[]
  deletedQuestions(state: IQuestionnaireStore, getters: ReadonlyIGetters): Backend.Questionnaire.IQuestion[]
  mappedQuestions(state: IQuestionnaireStore, getters: ReadonlyIGetters): Record<NonNullable<Backend.Questionnaire.QuestionID>, Backend.Questionnaire.IQuestion>
  massSelectedQuestions(state: IQuestionnaireStore, getters: ReadonlyIGetters): Backend.Questionnaire.IQuestion[]
  selectedQuestion(state: IQuestionnaireStore, getters: ReadonlyIGetters): Backend.Questionnaire.IQuestion | null
  partyQuestions(state: IQuestionnaireStore, getters: ReadonlyIGetters): Record<string, Backend.Questionnaire.IQuestion[]>
  questionAndOperationAsts(state: IQuestionnaireStore, getters: ReadonlyIGetters): (string | undefined)[]
  sidekiq_done(state: IQuestionnaireStore, getters: ReadonlyIGetters): boolean
  nonDependentAndDependentResolvedDatasheetAtts(state: IQuestionnaireStore, getters: ReadonlyIGetters): (string | undefined)[]
  hasAnswer(state: IQuestionnaireStore, getters: ReadonlyIGetters): (att: string) => boolean
}

const getters: IGetters = {
  questionsByAtt(state: IQuestionnaireStore, getters: ReadonlyIGetters): Record<string, Backend.Questionnaire.IQuestion> {
    return getters.nonDeletedQuestions.filter((q) => q.att).reduce((obj, q) => ((obj[q.att as string] = q), obj), Object.create(null))
  },
  questionByAtt:
    (state: IQuestionnaireStore, getters: ReadonlyIGetters) =>
    (att: string): Backend.Questionnaire.IQuestion | undefined => {
      return getters.nonDeletedQuestions.find((q) => q.att === att) as Backend.Questionnaire.IQuestion
    },
  nonDeletedQuestions: (state: IQuestionnaireStore) => state.questions.filter((q) => !q.deleted),
  deletedQuestions(state: IQuestionnaireStore) {
    return state.questions.filter((q) => q.deleted)
  },
  mappedQuestions(state: IQuestionnaireStore, getters: ReadonlyIGetters): Record<NonNullable<Backend.Questionnaire.QuestionID>, Backend.Questionnaire.IQuestion> {
    return getters.nonDeletedQuestions.filter((q) => q.opts.uuid).reduce((obj, q) => ((obj[q.opts.uuid as string] = q), obj), Object.create(null))
  },
  massSelectedQuestions(state: IQuestionnaireStore, getters: ReadonlyIGetters): Backend.Questionnaire.IQuestion[] {
    return state.mass_selected_questions.reduce((arr, uuid) => (arr.push(getters.mappedQuestions[uuid]), arr), [] as Backend.Questionnaire.IQuestion[])
  },
  selectedQuestion(state: IQuestionnaireStore, getters: ReadonlyIGetters): Backend.Questionnaire.IQuestion | null {
    return state.selected_question != null ? getters.mappedQuestions[state.selected_question] : null
  },
  expandedInfoTextModals(state: IQuestionnaireStore): string[] {
    return state.expanded_info_text_modals
  },
  openedInfoTextModals(state: IQuestionnaireStore): string[] {
    return state.opened_info_text_modals
  },
  partyQuestions(state: IQuestionnaireStore, getters: ReadonlyIGetters): Record<string, Backend.Questionnaire.IQuestion[]> {
    const result = getters.nonDeletedQuestions.reduce(
      (acc, el) => {
        if (!acc[el.party]) acc[el.party] = []
        acc[el.party].push(el)
        return acc
      },
      Object.create(null) as Record<string, Backend.Questionnaire.IQuestion[]>
    )
    state.questionnaires.forEach((p) => {
      if (result[p] == null) result[p] = []
    })
    return result
  },
  questionAndOperationAsts(state: IQuestionnaireStore, getters: ReadonlyIGetters) {
    const visConds = getters.nonDeletedQuestions.map((q) => q.cond).filter((e) => e)
    const operationAsts = state.operations?.map((o) => o.cond).filter((e) => e) || []
    const optionsVisConds = getters.nonDeletedQuestions
      .map((q) => q.opts.selectOptions?.map((opt) => opt.cond))
      .flat()
      .filter((e) => e)
    return Ast.uniqueArray(visConds.concat(operationAsts).concat(optionsVisConds))
  },
  sidekiq_done(state: IQuestionnaireStore) {
    return state.sidekiq_status == PROCESS_STATES.DONE
  },
  nonDependentAndDependentResolvedDatasheetAtts(state: IQuestionnaireStore, getters: ReadonlyIGetters) {
    const hasNoUnresolvedDependency = (q: Backend.Questionnaire.IQuestion) => !q.opts.datasheet_dependent_attribute || getters.hasAnswer(q.opts.datasheet_dependent_attribute)

    const isDatasheetQuestion = (q: Backend.Questionnaire.IQuestion) => q.type === 'datasheets'
    return getters.nonDeletedQuestions
      .filter(isDatasheetQuestion)
      .filter(hasNoUnresolvedDependency)
      .map((q) => q.att)
  },
  hasAnswer(state: IQuestionnaireStore) {
    return (att) => {
      const answer = state.answers[att]
      const isEmptyArray = (val) => {
        if (Array.isArray(val)) {
          return val.length === 0
        } else return false
      }
      if (!answer || isEmptyArray(answer)) {
        return false
      }

      return true
    }
  }
} as const

const mutations = {
  SET_DRAGGING(state: IQuestionnaireStore, value: boolean) {
    state.dragging = value
  },
  SET_SELECTED_PARTY(state: IQuestionnaireStore, partyName: string) {
    state.selected_party = partyName
  },
  CREATE_QUESTIONNAIRE(state: IQuestionnaireStore, partyName: string) {
    if (state.parties.indexOf(partyName) === -1) throw new Error('Trying to create questionnaire for not existing party.')
    state.questionnaires.push(partyName)
  },
  ADD_QUESTIONS(state: IQuestionnaireStore, { index = -1, data }: { index?: number; data: Backend.Questionnaire.IQuestion[] }) {
    const withoutDuplicates = data.filter(
      (q: Backend.Questionnaire.IQuestion, i: number, self: Backend.Questionnaire.IQuestion[]) =>
        self.findIndex((dq) => {
          if (['section', 'label'].includes(q.type)) return toPlainText(q.desc) == toPlainText(dq.desc)
          return dq.att === q.att
        }) === i
    )
    withoutDuplicates.forEach((question) => {
      if (!question.cond && 'EditorFactory' in window) question.cond = getVisibilityConditionForQuestion(question, EditorFactory.main)
      // if nothing was found, remove the key
      if (!question.cond) delete question.cond
      if (question.deleted) delete question.deleted
    })
    if (index === -1) {
      withoutDuplicates
        .map((q) => q.att || toPlainText(q.desc))
        .forEach((textIdentifier) => {
          const questionIndex = state.questions.findIndex((q) => {
            if (['section', 'label'].includes(q.type)) return toPlainText(q.desc) == textIdentifier
            return textIdentifier === q.att
          })
          if (questionIndex >= 0) state.questions.splice(questionIndex, 1)
        })
      state.questions.push(...withoutDuplicates)
    } else state.questions.splice(index, 0, ...withoutDuplicates)
  },
  REMOVE_QUESTION(state: IQuestionnaireStore, { data }: { data: number | Backend.Questionnaire.IQuestion }) {
    if (typeof data === 'number') {
      const question = state.questions[data]
      if (!question) throw new Error("Trying to remove content what doesn't exist at questionnaire")
      question.deleted = true
    } else {
      data.deleted = true
    }
  },
  REMOVE_QUESTIONS(state: IQuestionnaireStore, { data }: { data: Array<Backend.Questionnaire.IQuestion> }) {
    data.forEach((q) => {
      q.deleted = true
    })
  },
  SET_QUESTIONS(state: IQuestionnaireStore, { data }: { data: Array<Backend.Questionnaire.IQuestion> }) {
    state.questions.splice(0, state.questions.length)
    state.questions.push(...data)
  },
  REPLACE_QUESTION(
    state: IQuestionnaireStore,
    {
      oldQuestion,
      newQuestion
    }: {
      oldQuestion: Backend.Questionnaire.IQuestion
      newQuestion: Backend.Questionnaire.IQuestion
    }
  ) {
    if (oldQuestion.opts.uuid === newQuestion.opts.uuid) {
      const differentTypes = oldQuestion.type !== newQuestion.type
      Object.entries(newQuestion).forEach(([key, value]) => {
        oldQuestion[key as keyof Backend.Questionnaire.IQuestion] = value
      })
      if (differentTypes) oldQuestion.opts.default = ''
      return
    }

    const index = state.questions.findIndex(({ opts }) => opts.uuid === oldQuestion.opts.uuid)
    if (index === -1) {
      console.error("Trying to update question what wasn't found.")
    } else {
      state.questions.splice(index, 1, newQuestion)
    }
  },
  MOVE_QUESTIONS(state: IQuestionnaireStore, { refIndex, questions }: { refIndex: number; questions: Array<Backend.Questionnaire.IQuestion> }) {
    if (refIndex < 0 || questions.length === 0) {
      console.error('Wrong arguments for MOVE_QUESTION')
      return
    }

    questions.sort((a, b) => state.questions.indexOf(a) - state.questions.indexOf(b))

    questions.forEach((q) => {
      const index = state.questions.indexOf(q)
      if (index === -1) {
        console.error('Index was not found for question', q)
        return
      }
      state.questions.splice(index, 1)
    })

    state.questions.splice(refIndex, 0, ...questions)
  },
  SET_MASS_SELECT(state: IQuestionnaireStore, payload: Array<Backend.Questionnaire.QuestionID>) {
    state.mass_selected_questions = payload
  },
  SET_GENERATED(state: IQuestionnaireStore, payload: Array<Backend.Questionnaire.QuestionID>) {
    state.generated_questions = payload
  },
  SET_SELECTED_QUESTION(state: IQuestionnaireStore, payload: Backend.Questionnaire.QuestionID | null) {
    state.selected_question = payload
  },
  ADD_EXPANDED_INFO_TEXT_MODAL(state: IQuestionnaireStore, uuid: string) {
    state.expanded_info_text_modals.push(uuid)
  },
  REMOVE_EXPANDED_INFO_TEXT_MODAL(state: IQuestionnaireStore, uuid: string) {
    state.expanded_info_text_modals = state.expanded_info_text_modals.filter((id) => id !== uuid)
  },
  ADD_OPENED_INFO_TEXT_MODAL(state: IQuestionnaireStore, uuid: string) {
    state.opened_info_text_modals.push(uuid)
  },
  REMOVE_OPENED_INFO_TEXT_MODAL(state: IQuestionnaireStore, uuid: string) {
    state.opened_info_text_modals = state.opened_info_text_modals.filter((id) => id !== uuid)
  },
  TOGGLE_COMPRESS_QUESTIONS(state: IQuestionnaireStore) {
    state.compress_questions = !state.compress_questions
  },
  TOGGLE_SHOW_SETTINGS(state: IQuestionnaireStore) {
    state.show_template_settings = !state.show_template_settings
  },
  ADD_ANSWER(state: IQuestionnaireStore, { value, att, loopCount, type }: { value: string; att: string; loopCount: number; type?: string }) {
    loopCount = loopCount ? loopCount - 1 : loopCount!
    const isArrayQType = ['file_upload', 'multi_select'].includes(type ?? '')
    const isInsideRepeater = loopCount >= 0

    if (isInsideRepeater) {
      if (!state.answers[att]) state.answers[att] = []
      if (isArrayQType) {
        if (!Array.isArray(value)) {
          if (!state.answers[att][loopCount]) state.answers[att][loopCount] = []

          // replace the initial empty value:
          if (state.answers[att][loopCount].length === 1 && state.answers[att][loopCount][0] === '') state.answers[att][loopCount][0] = value
          else state.answers[att][loopCount].push(value)
          state.changed_attrs.push({ att, loopCount: loopCount + 1 })
        }
      } else {
        if (!compareValues(state.answers[att][loopCount], value, state.questionnaire_type)) {
          state.answers[att][loopCount] = value
          state.changed_attrs.push({ att, loopCount: loopCount + 1 })
        }
      }
    } else {
      if (isArrayQType) {
        if (!Array.isArray(value)) {
          if (!state.answers[att]) state.answers[att] = []

          // replace the initial empty value:
          if (state.answers[att].length === 1 && state.answers[att][0] === '') state.answers[att][0] = value
          else state.answers[att].push(value)

          state.changed_attrs.push({ att, loopCount: loopCount + 1 })
        }
      } else if (!compareValues(state.answers[att], value, state.questionnaire_type)) {
        state.answers[att] = value
        state.changed_attrs.push({ att, loopCount: loopCount + 1 })
      }
    }
  },
  ADD_LAST_SAVED_ENTRY(state: IQuestionnaireStore, { value, att, loopCount }: { value: string; att: string; loopCount?: number }) {
    const question = state.questions.find((q) => q.att === att)
    if(!question) return
    const type = question.type
    const isArrayQType = ['file_upload', 'multi_select'].includes(type)
    const isCurrencyWithModificator = type === 'currency' && value.split(currencySplitter)[1]
    const isPhoneWithModificator = type === 'phone' && value.split(currencySplitter)[1]

    const isInsideRepeater = !!question.opts['repeater-id']
    if (isInsideRepeater) {
      if (!state.last_saved_entries[att]) state.last_saved_entries[att] = []
      if (isArrayQType) {
        if (!Array.isArray(value)) {
          if (!state.last_saved_entries[att][loopCount!]) state.last_saved_entries[att][loopCount!] = []
          state.last_saved_entries[att][loopCount!].push(value)
        }
      } else {
        if (isCurrencyWithModificator || isPhoneWithModificator) {
          const modificator = value.split(currencySplitter)[1]
          state.last_saved_entries[att] = state.last_saved_entries[att].map((entry) => {
            const currVal = entry.split(currencySplitter)[0]
            return `${currVal}${currencySplitter}${modificator}`
          })
        }
        state.last_saved_entries[att][loopCount!] = value
      }
    } else {
      if (isArrayQType) {
        if (!Array.isArray(value)) {
          if (!state.last_saved_entries[att]) state.last_saved_entries[att] = []
          if(value !== '') state.last_saved_entries[att].push(value)
        }
      } else state.last_saved_entries[att] = value
    }
  },
  REMOVE_ANSWER(
    state: IQuestionnaireStore,
    {
      value,
      att,
      wipe = false,
      loopCount
    }: {
      value: string
      att: string
      wipe?: boolean
      loopCount?: number
    }
  ) {
    // expects 1-based loop count
    loopCount = loopCount ? loopCount - 1 : loopCount!
    if (wipe || !Array.isArray(state.answers[att])) delete state.answers[att]
    else {
      const question = state.questions.find((q) => q.att === att)
      if (!question) return
      const isInsideRepeater = question.opts?.['repeater-id']
      const isMultiSelect = question.type === 'multi_select'

      if (isInsideRepeater) {
        if (isMultiSelect) {
          state.answers[att][loopCount] = state.answers[att][loopCount].filter((a: string) => a !== value)
          state.changed_attrs.push({ att, loopCount: loopCount + 1 })
        } else {
          state.answers[att].splice(loopCount + 1, 1)
          state.changed_attrs.push({ att, loopCount: loopCount + 1 })
        }
      } else if (!isMultiSelect) state.answers[att].splice(index, 1)
      else {
        state.answers[att] = state.answers[att].filter((a) => a !== value)
        state.changed_attrs.push({ att, loopCount: loopCount + 1 })
      }
    }
  },
  SET_ANSWERS(state: IQuestionnaireStore, answers) {
    state.answers = answers
  },
  SET_PARTIES(state: IQuestionnaireStore, parties: string[]) {
    state.parties = parties
  },
  ON_PARTIES_CHANGE(state: IQuestionnaireStore, newPartiesNames: string[]) {
    const oldPartiesNames = state.parties

    state.parties = newPartiesNames

    const bPartyWasRemoved = oldPartiesNames.length > newPartiesNames.length

    if (bPartyWasRemoved) return
    const changedPartyNames = findChangedParty(oldPartiesNames, newPartiesNames)
    if (!changedPartyNames) return
    if (typeof changedPartyNames === 'string' && !state.questionnaires.includes(changedPartyNames)) state.questionnaires.push(changedPartyNames)
    else if (typeof changedPartyNames !== 'string') {
      const index = state.questionnaires.indexOf(changedPartyNames.oldPartyName)
      if (state.questionnaires.includes(changedPartyNames.newPartyName)) state.questionnaires.splice(index, 1)
      else state.questionnaires.splice(index, 1, changedPartyNames.newPartyName)
      state.questions.filter((q) => q.party === changedPartyNames.oldPartyName).forEach((question) => (question.party = changedPartyNames.newPartyName))
      if (state.selected_party === changedPartyNames.oldPartyName) state.selected_party = changedPartyNames.newPartyName
    }
  },
  DELETE_QUESTIONNAIRE(state: IQuestionnaireStore, name: string) {
    const index = state.questionnaires.indexOf(name)
    state.questionnaires.splice(index, 1)
  },
  SET_SHOW_DIALOG(state: IQuestionnaireStore, val: boolean) {
    state.show_dialog = val
  },
  SET_RESOLVED_CONDITIONS(state: IQuestionnaireStore, conds: IQuestionnaireStore['resolvedConditions']) {
    state.resolvedConditions = conds ?? {}
  },
  SET_CONDITION_LOGS(state: IQuestionnaireStore, logs: IQuestionnaireStore['conditionLogs']) {
    state.conditionLogs = logs ?? {}
  },
  SET_OPERATIONS(state: IQuestionnaireStore, operations: IQuestionnaireStore['operations']) {
    state.operations = operations
  },
  SET_CURRENT_SECTION(state: IQuestionnaireStore, section: IQuestionnaireStore['current_section'] | string) {
    state.current_section =
      typeof section === 'string'
        ? state.questions
            .filter((q) => q.type === 'section')
            .map((q) => q.desc)
            .indexOf(section)
        : isNaN(section)
          ? -1
          : section
  },
  START_SAVE_ENTRY(state: IQuestionnaireStore, { att, loopCount }: { att: string; loopCount?: number }) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if (isRepeater) {
      if (!Array.isArray(state.active_requests[att])) state.active_requests[att] = []
      if (!state.active_requests[att].includes(loopCount)) state.active_requests[att].push(loopCount)
    } else {
      if (typeof state.active_requests[att] == 'undefined') {
        state.active_requests[att] = 1
      } else state.active_requests[att]++
    }
  },
  END_SAVE_ENTRY(state: IQuestionnaireStore, { att, loopCount }: { att: string; loopCount?: number }) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if (isRepeater) {
      if (Array.isArray(state.active_requests[att])) state.active_requests[att].shift()
      if (!state.active_requests[att]?.length) delete state.active_requests[att]
    } else {
      state.active_requests[att]--
      if (!state.active_requests[att]) delete state.active_requests[att]
    }
  },
  ADD_SCHEDULED_REQUEST(state: IQuestionnaireStore, { att, loopCount }: { att: string; loopCount?: number }) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if (isRepeater) {
      if (!Array.isArray(state.scheduled_requests[att])) state.scheduled_requests[att] = []
      state.scheduled_requests[att].push(loopCount)
    } else {
      if (typeof state.scheduled_requests[att] == 'undefined') {
        state.scheduled_requests[att] = 1
      } else state.scheduled_requests[att]++
    }
  },
  REMOVE_SCHEDULED_REQUEST(state: IQuestionnaireStore, { att, loopCount }: { att: string; loopCount?: number }) {
    const isRepeater = loopCount != null && !isNaN(loopCount)
    if (isRepeater) {
      state.scheduled_requests[att]?.shift()
      if (!state.scheduled_requests[att]?.length) delete state.scheduled_requests[att]
    } else {
      state.scheduled_requests[att]--
      if (!state.scheduled_requests[att]) delete state.scheduled_requests[att]
    }
  },
  SET_SAVED_ANSWERS(state: IQuestionnaireStore, answers) {
    state.saved_answers = state.questionnaire_type === 'ld' ? answers : answers.filter((a) => a.allow_usage_in_documents)
  },
  SET_ACTIVE_FILTER(state: IQuestionnaireStore, filter) {
    state.mass_selected_questions = []
    state.active_filter = filter
  },
  SET_CONDITION_LOGIC(state: IQuestionnaireStore, val) {
    state.showConditionLogic = val
  },
  SET_LOOP_COUNT(state: IQuestionnaireStore, { value, repeater_id }) {
    state.loop_counts[repeater_id] = value
    const linkedRepeaters = Ast.uniqueArray(state.questions.filter((q) => q.opts['repeater-master-id'] === repeater_id).map((q) => q.opts['repeater-id']))
    linkedRepeaters.forEach((repeater) => {
      state.loop_counts[repeater as string] = value
    })
  },
  SET_SIDEKIQ_STATUS(state: IQuestionnaireStore, { sidekiq_status, immediate }: { sidekiq_status: PROCESS_STATES; immediate: boolean }) {
    useDebounce(
      () => {
        state.sidekiq_status = sidekiq_status
      },
      500,
      immediate
    )()
  },
  SET_DATASHEETS(state: IQuestionnaireStore, datasheets) {
    state.datasheets = datasheets
  },
  SET_DATASHEET_VALUES(state: IQuestionnaireStore, { values, att, loopCount = 0 }) {
    if (!state.datasheet_values[att]) state.datasheet_values[att] = []
    const index = Utils.notANumber(loopCount) ? 0 : loopCount
    state.datasheet_values[att][index] = values
  },
  SET_QUESTIONNAIRE_DATA(state: IQuestionnaireStore, questionnaireData: Backend.Questionnaire.IQuestionnaireData) {
    const { valid_conditions, entries_values, entries_values_localized, loop_counts, document_conditions, title } = questionnaireData
    const changedAttrs = state.questionnaire_type === 'ld' ? state.changed_attrs.map((entry) => entry.att) : Object.keys(state.active_requests).concat(Object.keys(state.scheduled_requests)).concat(Object.keys(state.changed_attrs))
    if (loop_counts) state.loop_counts = setLoopCounts(!state.created_from_pack, state.loop_counts, loop_counts)
    if (valid_conditions) state.resolvedConditions = valid_conditions
    if (entries_values_localized && state.formattedNumbers) {
      state.formattedNumbers = applyEntries(state.formattedNumbers, entries_values_localized, changedAttrs)
    }
    if (document_conditions) state.documentConditions = document_conditions
    if (title) state.documentTitle = title
    if (entries_values) state.answers = applyEntries(state.answers, entries_values, changedAttrs)
  },
  SET_SHOW_EDITOR(state: IQuestionnaireStore, val) {
    state.show_editor = val
  },
  SET_QUESTION_OPTIONS(state: IQuestionnaireStore, { att, options }) {
    const question = state.questions.find((question) => question.att === att)
    if (question) {
      question.opts.selectOptions = options
    }
  },
  SET_ERRORS(state: IQuestionnaireStore, errors: Backend.Questionnaire.Errors) {
    state.errors = errors
  },
  SET_UUID_BLACKLIST(state: IQuestionnaireStore, uuids: string[]) {
    state.uuidBlacklist = uuids
  },
  BLACKLIST_UUID(state: IQuestionnaireStore, uuid: string) {
    state.uuidBlacklist.push(uuid)
  },
  SET_SEARCH_QUERY(state: IQuestionnaireStore, query: string) {
    state.searchQuery = query
  },
  SANITIZE_QUESTIONS(state: IQuestionnaireStore){
    const generalOptions: Array<keyof Backend.Questionnaire.IQuestion['opts']> = ['placeholder', 'default', 'required', 'hidden', 'custom', 'color', 'repeater-id','repeater-master-id', 'repeater-name', 'repeater-label', 'loop_control', 'role', 'loop_label', 'repeater-merge', 'collapsed', 'informatoryText', 'informatoryTextAutofocus', 'uuid', 'pack']
    const allowedOptions: Record<Backend.Questionnaire.IQuestion['type'], (keyof Backend.Questionnaire.IQuestion['opts'])[]> = {
      checkbox: ["checkedValue", "uncheckedValue"],
        currency: [ 'informatoryText', 'informatoryTextAutofocus'],
        date: ["allowedFromValue", "allowedToValue", "dateLocale", "dateFormat"],
        dependentList: ["dependentListOn", "dependentListName"],
        datasheets: ["datasheet_id", "datasheet_display_header_id", "datasheet_dependencies"],
        input: ['charLimit', 'singleLine'],
        email: [],
        phone: [],
        listSelectDb: ["listSelectDbName"],
        multi_select: ["selectOptions", "selectOpen", "defaultSeparator", "penultimateSeparator", "endSeparator", "selectMode", "collectOptions"],
        number: ['charLimit', 'minValue', 'maxValue'],
        open_select: ["selectOptions", "selectMode", "collectOptions"],
        select: ["selectOptions", "selectMode", "collectOptions"],
        yes_no: ["selectOptions"],
        metadata: ["comp_type", "metadata_key"],
        file_upload: [],
        label: [],
        section: []
      }
    const optimized_repeaters: Record<string, boolean> = {}

    state.questions.forEach(question => {
        question.party = question.party.trim()
        if(question.cond == 'null') question.cond = undefined
        if(question.opts.role) question.opts.role = question.opts.role.trim()
        const questionOptsKeys = getObjectKeys(question.opts || {})
        const allowedKeys = generalOptions.concat(allowedOptions[question.type]).concat(allowedOptions[question.opts.comp_type!] || [])
        const unallowedKeys = questionOptsKeys.filter(key => !allowedKeys.includes(key))
        unallowedKeys.forEach(key => {
            delete question.opts[key]
        })

        /* ensure only one loop controll is present */
        const repeater = question.opts['repeater-master-id'] ?? question.opts['repeater-id']
        if(!repeater && question.opts.loop_control) question.opts.loop_control = undefined
        if(repeater && !optimized_repeaters[repeater]){
          const isFromRepeater = (q: Backend.Questionnaire.IQuestion) => ['repeater-id', 'repeater-master-id'].some(key => q.opts[key] === repeater)
          const isLoopControl = (q: Backend.Questionnaire.IQuestion) => q.opts.loop_control
          const repeaterQuestions = state.questions.filter(isFromRepeater)
          const repeaterLoopControls = repeaterQuestions.filter(isLoopControl)
          if(repeaterLoopControls.length > 1){
              repeaterLoopControls.pop()
              repeaterLoopControls.forEach(q => q.opts.loop_control = false)
          } else if (!repeaterLoopControls.length) {
              const lastQuestion = repeaterQuestions[repeaterQuestions.length - 1]
              lastQuestion.opts.loop_control = true
              lastQuestion.opts.loop_label = `Add another to ${lastQuestion.opts['repeater-name'] || lastQuestion.opts['repeater-id']}`
          }
          optimized_repeaters[repeater] = true
        }
    })

  }
}

interface ICreateQuestionnaireStore {
  questionnaires: Ref<IQuestionnaireStore['questionnaires']>
  questions: Ref<IQuestionnaireStore['questions']>
  newTemplatePackQuestions?: IQuestionnaireStore['questions']
  profile_id: number

  operations?: IQuestionnaireStore['operations']
  defaultMode?: DefaultMode
  displayToolbar?: boolean
  documentID?: number
  clipID?: number
  documentTitle?: IQuestionnaireStore['documentTitle']
  answers?: IQuestionnaireStore['answers']
  errors?: IQuestionnaireStore['errors']
  resolvedConditions?: Record<string, boolean> | Array<string>
  documentConditions?: Record<number, boolean>
  questionnaire_type?: string
  questionnaire_compress?: boolean
  documentPreview?: boolean
  sidekiq_status?: string
  process_sidekiq_status?: boolean
  formattedNumbers?: Backend.Questionnaire.Answers
  is_doc_negotiable?: boolean
  allow_suggested_definition?: boolean
  allow_change_q_type?: boolean
  allow_save?: boolean
  allow_load?: boolean
  created_from_pack?: boolean
  loop_counts?: Record<string, number>
  templates_by_attributes?: Record<string, string[]>
  open_ai_integration?: boolean
  allowSavedEntries?: boolean
  allowCreateDocument?: boolean
  allowFullscreen?: boolean
  allowUseSuggestedVC?: boolean
  bShowExportAnswers?: boolean
  newQuestionAsPack?: boolean
  fastTpq?: boolean
  templatePackID: number
  uuidBlacklist: string[]
}

export const createQuestionnaireStore = ({
  questionnaires,
  questions,
  newTemplatePackQuestions,
  operations,
  profile_id,
  formattedNumbers,
  is_doc_negotiable,
  loop_counts,

  defaultMode = 'templating',
  displayToolbar = true,
  documentID = 0,
  clipID = 0,
  documentTitle = localizeText('general.not_found'),
  answers = {},
  errors = {},
  resolvedConditions = undefined,
  documentConditions = undefined,
  questionnaire_type = 'obq',
  questionnaire_compress = false,
  documentPreview = true,
  sidekiq_status = PROCESS_STATES.DONE,
  process_sidekiq_status = false,
  allow_suggested_definition = true,
  allow_change_q_type = true,
  allow_save = false,
  allow_load = false,
  created_from_pack = false,
  templates_by_attributes = undefined,
  open_ai_integration = false,
  allowSavedEntries = true,
  allowCreateDocument = false,
  allowFullscreen = false,
  allowUseSuggestedVC = true,
  bShowExportAnswers = false,
  newQuestionAsPack = false,
  fastTpq = false,
  templatePackID = 0,
  uuidBlacklist = []
}: ICreateQuestionnaireStore) => {
  const store = Vuex.createStore<IQuestionnaireStore>({
    state: {
      document_id: documentID,
      clip_id: clipID,
      templatePackID,
      documentTitle: documentTitle,
      profile_id: profile_id,
      parties: [],
      questionnaires: clone(toRaw(questionnaires.value)),
      questions: clone(toRaw(questions.value)),
      newTemplatePackQuestions,
      operations: operations,
      mass_selected_questions: [],
      generated_questions: [],
      selected_question: null,
      expanded_info_text_modals: [],
      opened_info_text_modals: [],
      selected_party: questionnaires.value[0],
      compress_questions: questionnaire_compress,
      show_template_settings: false,
      dragging: false,
      default_mode: defaultMode,
      answers: answers,
      last_saved_entries: {},
      display_toolbar: displayToolbar,
      show_dialog: false,
      resolvedConditions: resolvedConditions,
      documentConditions: documentConditions,
      current_section: -1,
      errors,
      active_requests: {},
      scheduled_requests: {},
      sidekiq_status: sidekiq_status,
      process_sidekiq_status: process_sidekiq_status,
      questionnaire_type: questionnaire_type,
      saved_answers: [],
      active_filter: null,
      showConditionLogic: false,
      changed_attrs: [],
      loop_counts: loop_counts,
      datasheet_values: {},
      formattedNumbers: formattedNumbers,
      is_doc_negotiable: is_doc_negotiable,
      allow_change_q_type: allow_change_q_type,
      allow_suggested_definition: allow_suggested_definition,
      allow_save: allow_save,
      allow_load: allow_load,
      created_from_pack,
      templates_by_attributes,
      conditionLogs: {},
      show_editor: documentPreview,
      open_ai_integration,
      allowSavedEntries,
      allowCreateDocument,
      allowFullscreen,
      allowUseSuggestedVC,
      bShowExportAnswers,
      newQuestionAsPack,
      fastTpq,
      uuidBlacklist,
      searchQuery: ''
    },
    actions: actions as any,
    getters,
    mutations
  })
  store.watch(
    (state: IQuestionnaireStore) => state.questions,
    (value) => {
      questions.value = clone(toRaw(value))
    },
    { deep: true }
  )
  store.watch(
    (state: IQuestionnaireStore) => state.questionnaires,
    (value) => {
      questionnaires.value = clone(toRaw(value))
    },
    { deep: true }
  )
  store.watch(
    (state: IQuestionnaireStore) => state.answers,
    () => {
      void store.dispatch('on_answers_change')
      DOMEmit('questionnaire:answers-changed')
    },
    { deep: true }
  )
  store.watch(
    (state: IQuestionnaireStore) => state.documentConditions,
    (value) => void store.dispatch('on_document_conditions_change', value),
    { deep: true }
  )
  if (store.state.questionnaire_type === 'ld') {
    if(typeof avv_listen_on_select_tab !== "undefined") {
      // avv_listen_on_select_tab is undefined on create/preview document page
      avv_listen_on_select_tab((tab) => {
        const isLiveDemoTab = tab === 'livedemo'
        if(isLiveDemoTab) {
          void store.dispatch('on_answers_change', true)
        }
      })
    }
    void store.dispatch('on_answers_change', true)
    store.watch(
      (state: IQuestionnaireStore) => state.resolvedConditions,
      (value) => void store.dispatch('on_resolved_conditions_change', value),
      { deep: true }
    )
  }
  if (store.state.questionnaire_type === 'dq') {
    store.getters.nonDependentAndDependentResolvedDatasheetAtts.forEach((att: string) => {
      const answers = store.state.answers[att]
      const loopAmount = Array.isArray(answers) ? answers.length : undefined
      if (loopAmount) {
        for (let loopCount = 0; loopCount < loopAmount; loopCount++) {
          void store.dispatch('fetch_datasheet_values', { att, loopCount })
        }
      } else {
        void store.dispatch('fetch_datasheet_values', { att })
      }
    })
  }

  if (store.state.process_sidekiq_status) {
    void store.dispatch('fetch_sidekiq_status')
  }

  listenOnStatusChanges(store)

  if (window.AvvStore) {
    const templateVersionStore = useTemplateVersionStore(getActivePinia())
    if (templateVersionStore.hydrated) {
      watch(
        () => [...templateVersionStore.parties],
        (parties) => {
          store.commit('ON_PARTIES_CHANGE', parties)
        }
      )
    }

    synchronizeRefs([toRef(store.state, 'datasheets'), toRef(AvvStore.state, 'datasheets')])
    if (AvvStore.state.questions) {
      synchronizeRefs([toRef(store.state, 'questions'), toRef(AvvStore.state, 'questions')])
    }

    if (store.state.questionnaire_type === 'dq') {
      AvvStore.state.documents_for_cable.forEach((doc) => {
        listenOnStatusChanges(store, doc.id)
      })
    }
  }

  window.qStore = store
  return store as SmartStore<IQuestionnaireStore>
}

globalThis.createQuestionnaireStore = createQuestionnaireStore

export interface SmartStore<T> extends Omit<Store<T>, 'dispatch' | 'commit' | 'getters'> {
  dispatch<ActionType extends keyof typeof actions>(action: ActionType, payload?: Parameters<(typeof actions)[ActionType]>[1]): Promise<void>
  commit<MutationType extends keyof typeof mutations>(mutation: MutationType, payload?: Parameters<(typeof mutations)[MutationType]>[1]): void
  getters: { [P in keyof typeof getters]: ReturnType<(typeof getters)[P]> }
}

export const QUESTIONNAIRE_STORE: InjectionKey<SmartStore<IQuestionnaireStore>> = 'QuestionnaireStore' as unknown as InjectionKey<SmartStore<IQuestionnaireStore>>
window.QUESTIONNAIRE_STORE = QUESTIONNAIRE_STORE

declare global {
  const QUESTIONNAIRE_STORE: InjectionKey<SmartStore<IQuestionnaireStore>>
  interface Window {
    QUESTIONNAIRE_STORE: InjectionKey<SmartStore<IQuestionnaireStore>>
    qStore: SmartStore<IQuestionnaireStore>
  }
}
